import { createStackNavigator } from '@react-navigation/stack';
import Home from './Home';
import Setting from './Setting';
import DashBoard from './Dashboard'
import { useContext, useEffect } from 'react';
import { AppContext } from '../Context';
import Navigation from './Navigation';
import { useNavigation } from '@react-navigation/native';

const Stack = createStackNavigator();


export default function HomeNavigation() {
    const nav = useNavigation();
    const ctx = useContext(AppContext)

    useEffect(() => {
        nav.setOptions({
            header: () => (
                    <Navigation />
            )
        })
    }, [])
    return (
        <>
        <Stack.Navigator initialRouteName='Home' screenOptions={{ headerShown: false }}>
            <Stack.Screen name='Home' component={Home} />
            <Stack.Screen name='Setting' component={Setting} />
            <Stack.Screen name='Dashboard' component={DashBoard} />
        </Stack.Navigator>
        </>
    )
}