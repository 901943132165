import { createDrawerNavigator } from '@react-navigation/drawer';
import { useContext, useEffect, useState } from 'react';
import { View, Platform } from 'react-native';
import { AppContext } from '../Context';
import HomeNavigation from './HomeNavigation'
import NetInfo from "@react-native-community/netinfo";
import { host } from './Components/url';
import publicIP from 'react-native-public-ip';

const Drawer = createDrawerNavigator();

export default function Main() {
    const [Response, setResponse] = useState()
    useEffect(() => {
        publicIP()
            .then(ip => {
                fetch(`${host}/kale/requests/add/${ip}`, { method: "GET", headers: { 'Content-Type': 'application/json', }, })
                .then(data => data.json())
                .then(data => { setResponse(data.status) })
                .catch(error => console.log(error))
                // '47.122.71.234'
            })
            .catch(error => {
                console.log(error);
                // 'Unable to get IP address.'
            });
    }, [])

    return (
        <>
            <Drawer.Navigator initialRouteName='Enqoq'>
                <Drawer.Screen name='Enqoq' component={Enqoq} />
            </Drawer.Navigator>
        </>
    )
}
function Enqoq() {
    const ctx = useContext(AppContext)

    return (
        <>

            <>
                <View style={{ flexDirection: 'row', flex: 1, backgroundColor: ctx.bgColor }}>
                    <View style={{ width: Platform.OS === 'web' ? ctx.isSmallDevice ? 0 : '25%' : 0, height: Platform.OS === 'web' ? '100%' : ctx.isSmallDevice ? 0 : 0 }}>

                    </View>
                    <View style={{ width: Platform.OS === 'web' ? ctx.isSmallDevice ? '100%' : '50%' : '100%', height: '100%' }}>

                        {/* <Navigation /> */}
                        <HomeNavigation />
                    </View>
                    <View style={{ width: Platform.OS === 'web' ? ctx.isSmallDevice ? 0 : '25%' : 0, height: Platform.OS === 'web' ? '100%' : ctx.isSmallDevice ? 0 : 0 }}>

                    </View>
                </View>
            </>
        </>
    )
}