import { createContext, useEffect, useState } from "react";
import { useColorScheme, Text, Platform, Dimensions } from 'react-native'
import AsyncStorage from "@react-native-async-storage/async-storage";
import Animated, { Layout, ZoomIn, ZoomOut } from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
const { width } = Dimensions.get("window");
import HowTo from "./src/Howto";
import { host } from "./src/Components/url";
import Profile from "./src/Profile";
import { FirstTierResponses, SecondTierResponses } from './src/Components/RandomResponses'

export const AppContext = createContext();

export function AppProvider({ children }) {
  const nav = useNavigation();
  const [scheme, setSchem] = useState(useColorScheme());
  const rootTheme = useColorScheme()
  const [msg, setMsg] = useState('');

  const isSmallDevice = width < 768


  const [Streak, setStreak] = useState(0);
  const [Loses, setLoses] = useState(0);
  const [Guesses, setGuesses] = useState(0);
  const [TotalWin, setTotalWin] = useState(0);
  const [HighScore, setHighScore] = useState(0);
  const [LastWin, setLastWin] = useState("None");

  const [letters, setLetters] = useState([])
  const [tries, setTries] = useState(15)
  const [RandomWord, setRandomWord] = useState(' ');

  const [GameStatus, setGameStatus] = useState({
    has_played: Boolean,
    last_game_time: '',
    last_word: String,
  })

  // SCORES
  useEffect(() => {
    const cb = (e, streak) => {
      if (!streak || streak === null || streak === undefined) {
        AsyncStorage.setItem("data", JSON.stringify({ "streak": 0, "guesses": 0, "loses": 0, "total_win": 0, "high_score": 0, "last_win": 'None' }))
      } else {
        var data = JSON.parse(streak)
        setStreak(data.streak)
        setLoses(data.loses)
        setGuesses(data.guesses)
        setTotalWin(data.total_win)
        setHighScore(data.high_score)
        setLastWin(data.last_win)
      }
    }
    AsyncStorage.getItem("data", cb)
  }, [])
  const GetRandomWord = async () => {
    await fetch(`${host}/kale/requests/get-word/${new Date().getDate()} ${new Date().getMonth()} ${new Date().getFullYear()}`, { method: "GET", headers: { 'Content-Type': 'application/json', }, })
      .then(data => data.json())
      .then(data => {
        if (data.word) {
          setRandomWord(data.word)
        } else {
          ctx.myAlert("የዛሬውን ቃል ማግኘት አልተቻልም፣ ዕባኮትን ሚኪን ያነጋግሩ።")
        }
      })
      .catch(error => console.log(error))
  }
  // GAME STATUS
  useEffect(() => {
    const cb = (e, status) => {
      if (!status || status === null || status === undefined) {
        GetRandomWord()
        AsyncStorage.setItem("status", JSON.stringify({ "has_played": false, "last_game_time": new Date(), "last_word": RandomWord }))
      } else {
        var data = JSON.parse(status);
        setGameStatus({
          has_played: data.has_played,
          last_game_time: data.last_game_time,
          last_word: data.last_word ? data.last_word : RandomWord
        })
        const last_time = new Date(data.last_game_time).getTime()
        const today = new Date().getTime()
        var Difference_In_Time = today - last_time;

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if (Difference_In_Days < 1 && data.has_played) {
          setRandomWord(data.last_word)
          setLetters(data.last_word.split(""))
        } else {
          GetRandomWord()
        }
      }
    }
    AsyncStorage.getItem("status", cb)
  }, [])

  const TanedTextColor = '#ff5900';
  const DarkModeTextColor = `#bdc1c6`;
  const BlackWhiteTextColor = '#111';

  const TannedBgColor = '#301100';
  const DarkModeBgColor = '#0f0f0f';
  const BlackWhiteBgColor = '#e8e8e8';

  const TannedButtonColor = '#0f0f0f';
  const DarkModeButtonColor = '#262626';
  const BlackWhiteButtonColor = '#ffffff';

  const textColor = scheme === "Tan'ed" ? TanedTextColor : scheme === 'Dark Mode' || scheme === 'dark' ? DarkModeTextColor : scheme === 'Black - White' || scheme === 'light' ? BlackWhiteTextColor : BlackWhiteTextColor;
  const bgColor = scheme === "Tan'ed" ? TannedBgColor : scheme === 'Dark Mode' || scheme === 'dark' ? DarkModeBgColor : scheme === 'Black - White' || scheme === 'light' ? BlackWhiteBgColor : BlackWhiteBgColor;
  const buttonColor = scheme === "Tan'ed" ? TannedButtonColor : scheme === 'Dark Mode' || scheme === 'dark' ? DarkModeButtonColor : scheme === 'Black - White' || scheme === 'light' ? BlackWhiteButtonColor : BlackWhiteButtonColor;

  useEffect(() => {
    const themeCb = (e, r) => {
      if (!r) {
        AsyncStorage.setItem('theme', rootTheme)
      } else {
        setSchem(r)
      }
    }
    AsyncStorage.getItem('theme', themeCb)
  }, [])

  const setScheme = (theme) => {
    setSchem(theme);
    AsyncStorage.setItem('theme', theme)
  }
  const MyAlert = (msg) => {
    setMsg(msg)
    setTimeout(() => {
      setMsg('')
    }, 3000)
  }

  
  const Submit = (wordsList, guesses) => {
    const word = wordsList.join('')
    var today = new Date().toLocaleString();
    AsyncStorage.setItem("status", JSON.stringify({ "has_played": true, "last_game_time": today, "last_word": RandomWord }))
    setGameStatus({
      has_played: true,
      last_game_time: today,
      last_word: RandomWord
    })
    setGuesses(Guesses ? Guesses : 0 + guesses)
    setLetters(RandomWord.split(""))

    if (word === RandomWord) {
      var randomFirstTierResponse = FirstTierResponses[Math.floor(Math.random() * FirstTierResponses.length)];
      var randomSecondTierResponse = SecondTierResponses[Math.floor(Math.random() * SecondTierResponses.length)];
      if (guesses > 11) {
        MyAlert(randomSecondTierResponse)
      } else {
        MyAlert(randomFirstTierResponse)
      }
      AsyncStorage.setItem("data", JSON.stringify({ "streak": Streak + 1, "guesses": Guesses + guesses, "loses": Loses, "total_win": TotalWin + 1, "high_score": HighScore > Streak ? HighScore : HighScore + 1, "last_win": today }))
      setStreak(Streak ? Streak + 1 : 1)
      setTotalWin(TotalWin ? TotalWin : 0 + 1)
      setHighScore(HighScore > Streak ? HighScore : HighScore + 1)
      setLastWin(today)
    } else {
      setTries(tries ? tries : 0 + 1)
      MyAlert("አልመለሱትም! ቃሉ " + RandomWord + " ነበረ።")
      AsyncStorage.setItem("data", JSON.stringify({ "streak": 0, "guesses": Guesses + guesses, "loses": Loses + 1, "total_win": TotalWin, "high_score": HighScore, "last_win": LastWin }))
      setStreak(0)
      setLoses(Loses ? Loses : + 1)
    }
  }
  const NavigateToSetting = () => {
    nav.push("Setting")
  }

  const ButtonStyles = {
    backgroundColor: buttonColor,
    padding: 2,
    paddingVertical: 10,
    borderRadius: 5,
    margin: 4,
    minWidth: 35,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#80808085',
    borderWidth: scheme === 'Black - White' ? 1 : 0,
  }
  const AnswerBoxStyles = [ButtonStyles, { borderRadius: 0, borderColor: textColor, borderWidth: 1, backgroundColor: bgColor, minWidth: 60, minHeight: 60 }]
  const [user, setUser] = useState('')

  useEffect(() => {
    const cb = (e, user) => {
      if (user === null || user == undefined || !user) {
        fetch(`${host}/kale/requests/players/add`, { method: "GET", headers: { 'Content-Type': 'application/json', }, })
          .then(data => data.json())
          .then(data => {
            if (data.status === 'CREATED') {
              setUser(data.name)
              AsyncStorage.setItem("user", data.name)
            } else {
              ctx.myAlert("There was an error trying to create you an account Please contact the developer.")
            }
          })
          .catch(error => console.log(error))
      } else {
        setUser(user)
      }
    }
    AsyncStorage.getItem("user", cb)
  }, [])

  const [showProfile, setShowProfile] = useState(false);
  const [ShowHelp, setShowHelp] = useState(false);

  const props = {
    ShowHelp,
    setShowHelp,
    ButtonStyles,
    AnswerBoxStyles,
    scheme,
    buttonColor,
    textColor,
    bgColor,
    MyAlert,
    setScheme,
    Submit,
    NavigateToSetting,
    RandomWord,
    letters,
    setLetters,
    isSmallDevice,
    Streak,
    Loses,
    Guesses,
    TotalWin,
    HighScore,
    LastWin,
    GameStatus,
    user,
    setUser,
    showProfile,
    setShowProfile,
  }

  return (
    <AppContext.Provider
      value={props}>
      {children}
      <HowTo Show={ShowHelp} setShow={setShowHelp} />
      <Profile Show={showProfile} setShow={setShowProfile} />
      {msg ?
        <Animated.View layout={Layout.delay(100)} exiting={ZoomOut} entering={ZoomIn} id="snackbar"
          style={{
            backgroundColor: '#202020',
            textAlign: 'center',
            borderRadius: 5,
            padding: 10,
            position: 'absolute',
            top: 100,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            minWidth: Platform.OS === 'web' ? '40%' : '90%',
            borderWidth: 1,
            borderColor: 'white'
          }}>
          <Text style={{
            fontSize: 15,
            textAlign: 'center',
            color: 'white',
          }}>{msg}</Text></Animated.View>
        : null}
    </AppContext.Provider>
  );
};
