import Animated from "react-native-reanimated";
import { Platform, TouchableOpacity, View, Text, Linking } from 'react-native';
import { AppContext } from '../../Context';
import { useContext, useState } from 'react'
import RenderList from '../Components/List'
import Hr from "../Components/Hr";
var AmharicQuotes = [
    '“አይዞሽ ገለቴ” - አትሌት ቀነኒሳ በቀለ'
]
  

export default function Setting() {
    const ctx = useContext(AppContext);
    const [list, setList] = useState([])
    const [OpenThemeChoice, setOpen] = useState(false)
    const ChooseTheme = () => {
        setList(['Dark Mode', 'Black - White', "Tan'ed"])
        setOpen(true)
    }
    const CloseList = () => {
        setOpen(false)
        setList([])
    }
    const ChangeTheme = (theme) => {
        CloseList()
        ctx.setScheme(theme)
    }
    const OpenGmail = () => {
        Linking.openURL('mailto:mikiyas.haile.amele@gmail.com')
    }
    const OpenTelegram = () => {
        Linking.openURL('https://t.me/enqoq')
    }
    const OpenTwitter = () => {
        Linking.openURL('https://www.twitter.com/EnqoqGame')
    }
    return (
        <Animated.View style={{
            flex: 1,
            backgroundColor: ctx.bgColor,
        }}>
            <RenderList onPress={ChangeTheme} setOpen={setOpen} CloseList={CloseList} open={OpenThemeChoice} Items={list} />
            <View style={{
                width: '100%',
                height: '100%',
                borderWidth: Platform.OS === 'web' ? 1 : 0,
                borderColor: ctx.textColor + "80",
                alignSelf: 'center',
                borderBottomWidth: 0,
                borderTopWidth: 0,
            }}>
                <RenderStringWithPlaceHolder onPress={ChooseTheme} str='Theme' placeholder={ctx.scheme} />
                <RenderStringWithPlaceHolder onPress={OpenGmail} str='Contact' placeholder={'Email'} />
                <RenderStringWithPlaceHolder onPress={OpenTelegram} str='Community' placeholder={'Telegram'} />
                <RenderStringWithPlaceHolder onPress={OpenTwitter} str='News' placeholder={'Twitter'} />

                <Text style={{
                    fontFamily: "Bold",
                    color: ctx.textColor,
                    marginTop: 'auto',
                    marginBottom: 30
                }}>“አይዞሽ ገለቴ” - አትሌት ቀነኒሳ በቀለ</Text>
            </View>
        </Animated.View>
    )
}
export function RenderStringWithPlaceHolder({ str, placeholder, onPress }) {
    const ctx = useContext(AppContext);
    return (
        <>
            <TouchableOpacity onPress={onPress} style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                padding: 10
            }}>
                <Text style={{
                    fontSize: 20,
                    color: ctx.textColor,
                    fontFamily: 'A-Regular',
                }}>
                    {str}
                </Text>
                <Text style={{
                    fontFamily: 'Bold',
                    color: ctx.textColor + "99",
                    fontFamily: 'Kiros',
                    fontSize: 20,
                }}>{placeholder}</Text>
            </TouchableOpacity>
            {/* <Hr width={.4} /> */}
        </>
    )
}