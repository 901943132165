import { View, Text, TouchableOpacity, Platform, Image } from 'react-native';
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Context';
import Hr from '../Components/Hr';
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { useNavigation } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { host } from '../Components/url'
import Profile from '../Profile';


export default function Navigation() {
  const ctx = useContext(AppContext);
  const nav = useNavigation();

  return (
    <>
      <View style={{
        backgroundColor: ctx.bgColor,
        width: '100%',
        alignItems: 'center',
      }}>
        <View style={{
          width: Platform.OS === 'web' ? ctx.isSmallDevice ? '100%' : '50%' : '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 10,
          paddingTop: Platform.OS === 'web' ? 0 : 20,
          paddingHorizontal: 10
        }}>

          <TouchableOpacity onPress={() => ctx.setShowProfile(ctx.showProfile === true ? false : true)} style={{ marginTop: 6, justifyContent: 'center', alignItems: 'center' }}>
            <TouchableOpacity onPress={() => nav.navigate("Dashboard")}>
              <MaterialIcons name='leaderboard' size={30} color={ctx.textColor} />
            </TouchableOpacity>
          </TouchableOpacity>

          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <TouchableOpacity style={{
              justifyContent: 'center',
              alignSelf: 'center',
            }} onPress={() => nav.navigate("Home")}>
              <Text style={{
                fontFamily: "Bold",
                fontSize: 30,
                color: ctx.textColor,
              }}>
                ቃል
              </Text>
            </TouchableOpacity>

          </View>
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <TouchableOpacity style={{ marginLeft: 3 }} onPress={() => ctx.setShowHelp(true)}>
              <Ionicons name='help-circle-outline' size={35} color={ctx.textColor} />
            </TouchableOpacity>
            <TouchableOpacity style={{ marginLeft: 3 }} onPress={() => nav.navigate("Setting")}>
              <FontAwesome name='gear' size={30} color={ctx.textColor} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Hr />
    </>
  )
}
