import { useContext, useState, useEffect } from 'react'
import { Modal, TouchableOpacity, Text, View, TouchableWithoutFeedback, Platform } from 'react-native'
import Animated, { SlideInDown, SlideOutDown } from 'react-native-reanimated'
import { AppContext } from '../../Context'

export default function RenderList({ Items, open, CloseList, onPress }) {
    const ctx = useContext(AppContext);

    return (
        <Modal onDismiss={CloseList} onResponderEnd={CloseList} onRequestClose={CloseList} visible={open} animationType='fade' transparent>
            <TouchableWithoutFeedback onPress={() => (CloseList())}>
                <View style={{
                    flex: 1,
                    backgroundColor: Platform.OS === 'web' ? "#0d121690" : '#0d121650'
                }}>
                    <Animated.View entering={SlideInDown} exiting={SlideOutDown} style={{
                        backgroundColor: ctx.bgColor,
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                        marginTop: 'auto',
                        borderWidth: 1,
                        borderColor: ctx.textColor,
                        padding: 20
                    }}>
                        {Items.map((item, index) => {
                            return (
                                <TouchableOpacity key={index} onPress={()=>onPress(item)} style={{
                                    paddingVertical: 10
                                }}>
                                    <Text style={{
                                        color: ctx.textColor,
                                        fontSize: 17
                                    }}>
                                        {item}
                                    </Text>
                                </TouchableOpacity>
                            )
                        })}
                    </Animated.View>
                </View></TouchableWithoutFeedback>
        </Modal>
    )
}
