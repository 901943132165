import Animated from "react-native-reanimated";
import { Platform, View, Text } from 'react-native';
import { AppContext } from '../../Context';
import { useContext } from 'react';
import { RenderStringWithPlaceHolder } from '../Setting'
import { SecondaryDateAdded } from '../Components/DateAdded'

export default function DashBoard() {
    const ctx = useContext(AppContext);
    const {
        Streak,
        Loses,
        Guesses,
        TotalWin,
        HighScore,
        LastWin
    } = ctx
    return (
        <Animated.View style={{
            flex: 1,
            backgroundColor: ctx.bgColor,
        }}>
            <View style={{
                width: '100%',
                height: '100%',
                borderWidth: Platform.OS === 'web' ? 1 : 0,
                borderColor: ctx.textColor + "80",
                alignSelf: 'center',
                borderBottomWidth: 0,
                borderTopWidth: 0,
            }}>
                <Text style={{ fontSize: 20, fontFamily: 'Addis', color: ctx.textColor, alignSelf:  'center', marginTop: 5 }}>{ctx.user}</Text>
                <RenderStringWithPlaceHolder onPress={()=>(console.log(''))} str={'ከፍተኛ ነጥብ'} placeholder={HighScore} />
                <RenderStringWithPlaceHolder onPress={()=>(console.log(''))} str={'ተከታታይ ድሎች'} placeholder={Streak} />
                <RenderStringWithPlaceHolder onPress={()=>(console.log(''))} str={'ሽንፈቶች'} placeholder={Loses} />
                <RenderStringWithPlaceHolder onPress={()=>(console.log(''))} str={'ሙከራዎች'} placeholder={Guesses} />
                <RenderStringWithPlaceHolder onPress={()=>(console.log(''))} str={'የመጨረሻ ድል'} placeholder={LastWin} />
                <RenderStringWithPlaceHolder onPress={()=>(console.log(''))} str={'ጠቅላላ ድሎች'} placeholder={TotalWin} />
            </View>
        </Animated.View>
    )
}