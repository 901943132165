import { Text, View, TouchableHighlight, Platform, } from 'react-native';
import { Fidel, extras, FidelTwo } from '../Components/Alphabets';
import { useContext, useEffect, useState } from 'react'
import Ionicons from 'react-native-vector-icons/Ionicons'
import AntDesign from 'react-native-vector-icons/AntDesign'
import { AppContext } from '../../Context';
import RenderKey from './RenderKey'
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Keyboard({ ButtonStyles }) {
    const ctx = useContext(AppContext)
    const [Family, setFamily] = useState(extras)
    const letters = ctx.letters
    const [keyCounter, setKeyCounter] = useState(0)
    const [fidel, setFidel] = useState({
        fidels: Fidel,
        page: 0
    })
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        var x = setInterval(function () {
            const cb = (e, status) => {
                const data = JSON.parse(status)
                if (data.last_game_time) {
                    var lastGame = new Date(data.last_game_time).getTime() + (24 * 60 * 60 * 1000);
                    var now = new Date().getTime();
                    var distance = lastGame - now;
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    if (data.has_played === true) {
                        setTimeLeft(hours + " ሰዓት " + minutes + " ደቂቃ " + seconds + " ሴኮንድ ")
                        if (distance < 0) {
                            clearInterval(x);
                            setTimeLeft("none")
                        }
                    } else {
                        setTimeLeft("none")
                    }
                } else {
                    setTimeLeft("none")
                }
            }
            AsyncStorage.getItem("status", cb)
        }, 1000)
        return () => clearInterval(x)
    })
    return (
        <>
            {timeLeft === 'none' ? null : <>
                <Text style={{ fontFamily: 'A-Regular', color: ctx.textColor, alignSelf: 'center', marginTop: 'auto', fontSize: 18 }}>ቀጣይ ጨዋታ</Text>
                <Text style={{ fontFamily: 'A-Regular', color: ctx.textColor, alignSelf: 'center', fontSize: 18 }}>በ {timeLeft}</Text></>}
            <Text style={{ fontFamily: 'A-Regular', color: ctx.textColor, alignSelf: 'center', marginTop: 'auto', fontSize: 18 }}>{keyCounter} / 15 ሙከራዎች ተጠቅመዋል!</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignSelf: "center" }}>
                {Family.map((item, index) => {
                    const RenderKeyProps = {
                        setKeyCounter: setKeyCounter,
                        Family: Family,
                        Fidel: Fidel,
                        index: index,
                        ButtonStyles: ButtonStyles,
                        key: index,
                        item: item,
                        keyCounter: keyCounter
                    }
                    return (
                        <RenderKey {...RenderKeyProps} />
                    )
                })}

                {Family.length > 0 &&
                    <TouchableHighlight
                        onPress={() => {
                            setFamily([])
                        }}
                        style={[ButtonStyles, { paddingHorizontal: 20 }]}>
                        <AntDesign name='down' color={ctx.textColor} />
                    </TouchableHighlight>
                }
            </View>

            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignSelf: "center", width: Platform.OS === 'web' ? ctx.isSmallDevice ? '100%' : '60%' : '100%' }}>
                {fidel.fidels.map((item, index) => {
                    return (
                        <TouchableHighlight onPress={() => {
                            setFamily(item)
                        }}
                            style={ButtonStyles}
                            key={index}>
                            <Text style={{ color: ctx.textColor, fontSize: 20, fontFamily: 'Addis' }}>
                                {item[0].fidel}
                            </Text>
                        </TouchableHighlight>
                    )
                })}
                <TouchableHighlight onPress={() => {
                    setFidel({ fidels: fidel.page === 0 ? FidelTwo : Fidel, page: fidel.page === 0 ? 1 : 0 })
                }}
                    style={ButtonStyles} >
                    <Text style={{ color: ctx.textColor, fontSize: 20, fontFamily: 'Kiros' }}>
                        <AntDesign name={fidel.page === 0 ? 'right' : 'left'} color={ctx.textColor} />
                        {/* more */}
                    </Text>
                </TouchableHighlight>
            </View>


            <View style={{
                flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center'
            }}>

                <TouchableHighlight
                    onPress={() => {
                        setKeyCounter(0)
                        ctx.Submit(letters, keyCounter)
                    }}
                    style={[ButtonStyles, { paddingHorizontal: 20 }]}>
                    <Text style={{ color: ctx.textColor, fontSize: 16 }}>
                        SKIP
                    </Text>
                </TouchableHighlight>


                <TouchableHighlight
                    onPress={() => {
                        ctx.setLetters([...letters, " "])
                    }}
                    style={[ButtonStyles, { paddingHorizontal: 50 }]}>
                    <Text style={{ color: ctx.textColor, fontSize: 16 }}>
                        SPACE
                    </Text>
                </TouchableHighlight>


                <TouchableHighlight
                    onPress={() => {
                        ctx.setLetters(letters.slice(0, -1))
                    }}
                    style={[ButtonStyles, { paddingHorizontal: 20 }]}>
                    {/* BackSpoce */}
                    <Ionicons name='md-backspace-sharp' size={17} color={ctx.textColor} />
                </TouchableHighlight>


            </View>
        </>
    )
}
