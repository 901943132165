export const FirstTierResponses = [
    "Gobez!",
    "ጎበዝ!",
    'አንበሳ!',
    'ጀግና!'
]

export const SecondTierResponses = [
    'ለትንሽ!',
    'ለጥቂት!!',
    'በለው!'
]