export const Fidel = [
    [
        { 'fidel': 'ሀ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሁ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሂ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሃ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሄ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ህ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሆ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ለ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሉ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሊ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ላ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሌ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ል', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሎ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ሐ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሑ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሒ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሓ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሔ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሕ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሖ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'መ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሙ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሚ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ማ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሜ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ም', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሞ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ሠ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሡ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሢ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሣ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሤ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሥ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሦ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ረ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሩ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሪ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ራ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሬ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ር', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሮ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ሰ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሱ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሲ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሳ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሴ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ስ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሶ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ሸ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሹ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሺ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሻ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሼ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሽ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ሾ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ቀ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቁ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቂ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቃ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቄ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቅ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቆ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ቐ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቑ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቒ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቓ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቔ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቕ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቖ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        {
            'fidel': 'በ', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        },
        { 'fidel': 'ቡ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቢ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ባ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቤ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ብ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቦ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ቨ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ቩ', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        },
        { 'fidel': 'ቪ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቫ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቬ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቭ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቮ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        {
            'fidel': 'ተ', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        },
        { 'fidel': 'ቱ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ቲ', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        },
        { 'fidel': 'ታ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቴ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ት', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቶ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        {
            'fidel':
                'ቸ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        },
        {
            'fidel': 'ቹ', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        },
        { 'fidel': 'ቺ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ቻ', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        },
        { 'fidel': 'ቼ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ች', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ቾ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ኀ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel':
                'ኁ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        },
        {
            'fidel': 'ኂ', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        },
        { 'fidel': 'ኃ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ኄ', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        },
        { 'fidel': 'ኅ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኆ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ነ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኑ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel':
                'ኒ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        },
        {
            'fidel': 'ና', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        },
        { 'fidel': 'ኔ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ን', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        },
        { 'fidel': 'ኖ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ኘ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኙ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኚ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel':
                'ኛ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        },
        {
            'fidel': 'ኜ', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        },
        { 'fidel': 'ኝ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ኞ', 'isGreen':
                'false', 'isOrange': 'false', 'isDark': 'false'
        }
    ],
    [
        { 'fidel': 'አ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኡ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኢ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኣ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel':
                'ኤ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        },
        {
            'fidel': 'እ', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        },
        { 'fidel': 'ኦ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ከ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኩ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኪ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ካ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኬ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel':
                'ክ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        },
        {
            'fidel': 'ኮ', 'isGreen': 'false', 'isOrange': 'false',
            'isDark': 'false'
        }
    ],
    [
        { 'fidel': 'ኸ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኹ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኺ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኻ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኼ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ኽ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel':
                'ኾ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false'
        }
    ],
    [
        { 'fidel': 'ወ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዉ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዊ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዋ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዌ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ው', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዎ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ዐ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዑ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዒ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዓ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዔ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዕ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዖ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ዘ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዙ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዚ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዛ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዜ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዝ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዞ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ዠ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዡ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዢ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዣ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዤ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዥ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዦ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'የ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዩ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዪ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ያ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዬ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ይ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዮ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
]

export const FidelTwo = [

    [
        { 'fidel': 'ደ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዱ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዲ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዳ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዴ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ድ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዶ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ዸ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዹ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዺ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዻ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዼ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዽ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ዾ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ጀ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጁ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጂ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጃ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጄ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጅ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጆ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ገ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጉ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጊ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጋ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጌ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ግ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጎ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ጘ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጙ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጚ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጛ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጜ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጝ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጞ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ጠ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጡ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጢ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጣ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጤ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጥ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጦ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ጨ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጩ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጪ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጫ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጬ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጭ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጮ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ጰ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጱ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጲ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጳ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጴ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጵ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጶ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        {
            'fidel': 'ጸ', 'isGreen': 'false', 'isOrange': 'false', 'isDark':
                'false'
        },
        { 'fidel': 'ጹ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጺ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጻ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጼ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጽ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ጾ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ፀ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ፁ', 'isGreen': 'false', 'isOrange': 'false', 'isDark':
                'false'
        },
        { 'fidel': 'ፂ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፃ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፄ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፅ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፆ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ፈ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፉ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ፊ', 'isGreen': 'false', 'isOrange': 'false', 'isDark':
                'false'
        },
        { 'fidel': 'ፋ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፌ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፍ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፎ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': 'ፐ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፑ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፒ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        {
            'fidel': 'ፓ', 'isGreen': 'false', 'isOrange': 'false', 'isDark':
                'false'
        },
        { 'fidel': 'ፔ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፕ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': 'ፖ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ],
    [
        { 'fidel': '፡', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': '።', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': '፣', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': '፤', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': '፥', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': '፦', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
        { 'fidel': '፧', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' }
    ]
]

export const extras = [
    { 'fidel': 'ቃ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': 'ሉ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': 'ን', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': ' ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': 'ይ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': 'ገ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': 'ም', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': 'ቱ', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
    { 'fidel': '!', 'isGreen': 'false', 'isOrange': 'false', 'isDark': 'false' },
]