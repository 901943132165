import Animated, { SlideOutLeft, SlideInRight } from 'react-native-reanimated';
import { Platform, Text, View, TouchableHighlight, TouchableWithoutFeedback, ScrollView } from 'react-native'
import { AppContext } from '../../Context';
import { useContext, useEffect, useState } from 'react';
import Hr from '../Components/Hr';
import Ionicons from 'react-native-vector-icons/Ionicons';


export default function HowTo({ Show, setShow }) {
    const ctx = useContext(AppContext)
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 1000)
        return (
            setShow(false)
        )
    }, [])

    return (
        <>
            {Show &&
                <TouchableWithoutFeedback onPress={() => setShow(false)} >
                    <View style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        flex: 1,
                        backgroundColor: ctx.bgColor + "90",
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Animated.View exiting={SlideOutLeft} entering={SlideInRight} style={{
                            width: Platform.OS === 'web' ? ctx.isSmallDevice ? '100%' : '50%' : '100%',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            backgroundColor: ctx.bgColor,
                            borderColor: ctx.textColor,
                            borderWidth: 1,
                            padding: 5,
                            maxHeight: '80%'
                        }}>
                            <Header setShow={setShow} Show={Show} />
                            <Hr />
                            <ScrollView style={{ padding: 10 }}>
                                <Body />
                            </ScrollView>
                        </Animated.View>
                    </View>
                </TouchableWithoutFeedback>
            }
        </>
    )
}
function Body() {
    const ctx = useContext(AppContext);
    const TextStyle = {
        fontFamily: 'Bold',
        fontSize: 14,
        color: ctx.textColor,
        marginVertical: 2
    }
    const exampleWord = ['አ', 'በ', 'በ'];
    const firstExample = ['አ', ' ', ' '];
    const secondExample = ['ሰ', 'አ', 'ት'];
    const thirdExample = ['ሰ', 'ዐ', 'ት'];
    return (
        <View style={{ padding: 10 }}>
            <Text style={TextStyle}>
                የተደበቀውን ቃል ፈልጉ።
            </Text>
            <Text style={TextStyle}>
                በተደበቀው ቃል ውስጥ ያሉት ፊደላት ቁጥር በተደረደሩት አራት ማእዘናት ብዛት ልክ ነው።
            </Text>
            <Text style={[TextStyle, { fontSize: 17 }]}>ምሳሌ፥</Text>
            <Text style={TextStyle}>የተደበቀው ቃል "አበበ" ነው እንበል።</Text>
            <Text style={TextStyle}>የተደበቀው ቃል 3 (ሶስት) ፊደል ኣሉት። ስለዚህም 3 (ሶስት) አራት ማዕዘናት አሉ።</Text>

            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
                {exampleWord.map((item, index) => {
                    return (
                        <View style={[ctx.AnswerBoxStyles, { width: 10, height: 10, padding: 5, backgroundColor: ctx.bgColor }]} key={index}>
                            <Text style={{ color: ctx.textColor, fontSize: 25, fontFamily: "Bold" }}> </Text>
                        </View>
                    )
                })}
            </View>
            <Text style={TextStyle}>
                ፊደል ከነኩ በኋላ፣ የነኩት ፊደል አራት ማእዘኑ ውስጥ ይገባል።
            </Text>
            <Text style={TextStyle}>• ማእዘኑ ወደ ኣረንጓዴ ቀለም ከተለወጠ፣ ፊደሉ በትክክለኛው ቦታ ነው ማለት ነው።</Text>

            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
                {firstExample.map((item, index) => {
                    return (
                        <View style={[ctx.AnswerBoxStyles, { width: 10, height: 10, padding: 5, backgroundColor: item === ' ' ? ctx.bgColor : 'green' }]} key={index}>
                            <Text style={{ color: ctx.textColor, fontSize: 25, fontFamily: "Bold" }}>{item}</Text>
                        </View>
                    )
                })}
            </View>
            <Text style={TextStyle}>• ማእዘኑ ወደ ብርቱካናማ ቀልም ከተለወጠ፣ ይህ ፊደል በተድብቀው ቃል ውስጥ ሲሆን፣ በትክክለኛው ቦታ አይደለም ማለት ነው።</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
                {secondExample.map((item, index) => {
                    return (
                        <View style={[ctx.AnswerBoxStyles, { width: 10, height: 10, padding: 5, backgroundColor: item === 'አ' ? 'orange' : ctx.bgColor }]} key={index}>
                            <Text style={{ color: ctx.textColor, fontSize: 25, fontFamily: "Bold" }}>{item}</Text>
                        </View>
                    )
                })}
            </View>
            <Text style={TextStyle}>• በመጨረሻም፥ ማእዘኑ ቀለም ካልለወጠ፣ ያስገቡት ፊደል ትክክል አይደለም ማለት ነው።</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
                {thirdExample.map((item, index) => {
                    return (
                        <View style={[ctx.AnswerBoxStyles, { width: 10, height: 10, padding: 5, backgroundColor: ctx.bgColor }]} key={index}>
                            <Text style={{ color: ctx.textColor, fontSize: 25, fontFamily: "Bold" }}>{item}</Text>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}
function Header({ setShow }) {
    const ctx = useContext(AppContext)

    return (
        <View style={{
            alignItems: 'center',
            flexDirection: 'row',
            padding: 10
        }}>
            <Text style={{
                color: ctx.textColor,
                fontFamily: 'Kiros',
                fontSize: 25
            }}>
                አጨዋውት
            </Text>
            <TouchableHighlight
                style={{
                    marginLeft: 'auto'
                }}
                onPress={() => setShow(false)}>
                <Ionicons name='close' color={ctx.textColor} size={30} />
            </TouchableHighlight>
        </View>
    )
}