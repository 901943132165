import { Text, TouchableHighlight } from 'react-native';
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Context';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';


export default function RenderKey({ item, ButtonStyles, setKeyCounter, Family, keyCounter }) {
    const ctx = useContext(AppContext);
    let letters = ctx.letters;
    const [IsGreen, setIsGreen] = useState(false);
    const [IsOrange, setIsOrange] = useState(false);
    const [IsDark, setIsDark] = useState(false);

    useEffect(() => {
        setIsGreen(item.isGreen)
        setIsOrange(item.isOrange)
        setIsDark(item.isDark)
    }, [item])

    const HandlePress = () => {
        setKeyCounter(keyCounter + 1)
        if (keyCounter < 15) {
            ctx.setLetters([...letters, item.fidel])
            letters = [...letters, item.fidel]

            if (ctx.RandomWord.includes(item.fidel)) {
                setIsOrange(true)
                Family.filter(x => x.fidel === item.fidel)[0].isOrange = true
            }
            else {
                setIsDark(true)
                Family.filter(x => x.fidel === item.fidel)[0].isDark = true
                Family.filter(x => x.fidel === item.fidel)[0].isOrange = false
                Family.filter(x => x.fidel === item.fidel)[0].isGreen = false
            }
            if (ctx.RandomWord.length == letters.length) {
                setKeyCounter(0)
                ctx.Submit(letters, keyCounter)
            }
        } else {
            ctx.MyAlert("ሁሉንም የሙከራ ግዜ ተጠቅመዋል! ነገ የምጡ!")
            ctx.Submit(letters, keyCounter)
        }
    }
    const KeyStyles = [ButtonStyles, { backgroundColor: IsGreen === true ? 'green' : IsOrange === true ? '#ff9100' : IsDark === true ? "#787c7e" : ButtonStyles.backgroundColor }]
    return (
        <Animated.View entering={FadeIn} exiting={FadeOut}>
            <TouchableHighlight
                onPress={HandlePress}
                style={KeyStyles}>
                <Text style={{ color: ctx.textColor, fontSize: 18, fontFamily: 'Addis' }}>
                    {item.fidel}
                </Text>
            </TouchableHighlight>
        </Animated.View>
    )
}