import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font'
import { AppProvider } from './Context';
import Main from './src/App';
import { NavigationContainer } from '@react-navigation/native'
import AppLoading from 'expo-app-loading';
import { Platform } from 'react-native';

export default function App() {
  const [loaded] = useFonts({
    'Bold': {
      uri: require('./assets/Fonts/NotoSerifEthiopic-Bold.ttf'),
    },
    'Kiros': {
      uri: require('./assets/Fonts/Kiros.ttf'),
    },
    'A-Regular': {
      uri: require('./assets/Fonts/AbyssinicaSIL-Regular.ttf'),
    },
    'Addis': {
      uri: require('./assets/Fonts/Addis.ttf'),
    },
  })
  
  
  if (Platform.OS === 'web') {

    return (
      <NavigationContainer>
        <AppProvider>
          <StatusBar hidden={false} />
          {/* <Navigation /> */}
          <Main loaded={loaded} />
        </AppProvider>
      </NavigationContainer>
    );
  } else {

    if (!loaded) {
      return (
        <AppLoading />
      )
    } else {
      return (
        <NavigationContainer>
          <AppProvider>
            <StatusBar hidden={false} />
            {/* <Navigation /> */}
            <Main loaded={loaded} />
          </AppProvider>
        </NavigationContainer>
      );
    }
  }
}
