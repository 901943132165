import { Text, View } from 'react-native';
import { useContext } from 'react'
import { AppContext } from '../../Context';


export default function AnswerBox({ AnswerBoxStyles }) {
    const ctx = useContext(AppContext);
    return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
            {ctx.RandomWord.split(/(?!$)/u).map((item, index) => {
                return (
                    <View style={[AnswerBoxStyles, { backgroundColor: item === ctx.letters[index] ? 'green' : ctx.RandomWord.includes(ctx.letters[index]) ? '#ff9100' : ctx.bgColor }]} key={index}>
                        <Text style={{ color: ctx.textColor, fontSize: 30, fontFamily: "Bold" }}>{ctx.letters[index] === undefined ? "  " : ctx.letters[index]}</Text>
                    </View>
                )
            })}
        </View>
    )
}