import { View } from 'react-native';
import { useContext } from 'react'
import Keyboard from '../Keyboard'
import AnswerBox from '../AnswerBox'
import { AppContext } from '../../Context';

export default function Home() {
    const ctx = useContext(AppContext)
    const ButtonStyles = {
        backgroundColor: ctx.buttonColor,
        padding: 2,
        paddingVertical: 10,
        borderRadius: 5,
        margin: 4,
        minWidth: 35,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#80808085',
        borderWidth: ctx.scheme === 'Black - White' ? 1 : 0,
    }
    const AnswerBoxStyles = [ButtonStyles, { borderRadius: 0, borderColor: ctx.textColor, borderWidth: 1, backgroundColor: ctx.bgColor, minWidth: 60, minHeight: 60 }]
    
    return (
        <>
             <View style={{ flex: 1, backgroundColor: ctx.bgColor }}> 
                {/* <View style={{ width: Platform.OS === 'web' ? ctx.isSmallDevice ? 0 : '25%' : 0, height: Platform.OS === 'web' ? '100%' : ctx.isSmallDevice ? 0 : 0 }}>

                </View>
                <View style={{ width: Platform.OS === 'web' ? ctx.isSmallDevice ? '100%' : '50%' : '100%', height: '100%' }}> */}
            <AnswerBox AnswerBoxStyles={AnswerBoxStyles} />
            <Keyboard ButtonStyles={ButtonStyles} />
            {/* </View>
                <View style={{ width: Platform.OS === 'web' ? ctx.isSmallDevice ? 0 : '25%' : 0, height: Platform.OS === 'web' ? '100%' : ctx.isSmallDevice ? 0 : 0 }}>

                </View> */}
            </View>
        </>
    )
}
