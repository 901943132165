import { View } from 'react-native';
import { useContext } from 'react'
import { AppContext } from '../../Context';


export default function Hr({ width }) {
    const ctx = useContext(AppContext)
    return (
        <View style={{
            borderWidth: width ? width : .5,
            borderColor: ctx.textColor === 'black' ? '#000000' : ctx.textColor + "99",
        }} />
    )
}