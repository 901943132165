import { useEffect, useContext, useState } from 'react'
import { AppContext } from '../../Context'
import { View, Text, TouchableHighlight, TextInput, Platform } from 'react-native'
import Animated, { SlideOutLeft, SlideInRight } from 'react-native-reanimated';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Hr from '../Components/Hr';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { host } from '../Components/url';


export default function Profile({ Show, setShow }) {
    const ctx = useContext(AppContext);
    const [name, setName] = useState('');
    const Change = async () => {
        await fetch(`${host}/kale/requests/players/change`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Referer": host
            },
            body: {
                'old_name': ctx.user,
                'new_name': name
            }
        })
            .then(data => data.json())
            .then(data => {
                s
                console.log(data)
                if (data.status === 'CHANGED') {
                    setUser(data.name)
                    AsyncStorage.setItem("user", data.name)
                    ctx.MyAlert("Successfully updated your profile")
                } else {
                    ctx.MyAlert("There was an error trying to update you an account Please contact the developer.")
                }
            })
            .catch(error => console.log("ERROR: ", error))
    }

    return (
        <>
            {Show &&
                <View style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    flex: 1,
                    backgroundColor: ctx.bgColor + "90",
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Animated.View exiting={SlideOutLeft} entering={SlideInRight} style={{
                        width: Platform.OS === 'web' ? ctx.isSmallDevice ? '100%' : '50%' : '100%',
                        backgroundColor: ctx.bgColor,
                        borderColor: ctx.textColor,
                        borderWidth: 1,
                        paddingHorizontal: 5,
                        padding: 5,
                        maxHeight: '80%'
                    }}>
                        <Header setShow={setShow} Show={Show} />
                        <View>
                            <Text style={{ color: ctx.textColor, fontSize: 14, fontFamily: 'Bold', paddingHorizontal: 20, marginTop: 10 }}>ስሞትን ይቀይሩ</Text>
                            <TextInput
                                value={name}
                                onChangeText={setName}
                                placeholder='ስሞትን
                        ማን ልበል?'
                                placeholderTextColor={ctx.textColor}
                                style={{
                                    borderWidth: 1,
                                    borderColor: ctx.textColor,
                                    borderRadius: 20,
                                    margin: 10,
                                    padding: 10,
                                    color: ctx.textColor,
                                }} />
                        </View>

                        {name ?
                            <TouchableHighlight
                                onPress={Change}
                                style={{
                                    backgroundColor: ctx.textColor,
                                    padding: 5,
                                    borderRadius: 30
                                }}>
                                <Text style={{
                                    color: ctx.bgColor,
                                    fontSize: 15,
                                    fontFamily: 'Bold',
                                    alignSelf: 'center'
                                }}>
                                    Save
                                </Text>
                            </TouchableHighlight> : null}
                    </Animated.View>
                </View>
            }
        </>
    )
}
function Header({ setShow }) {
    const ctx = useContext(AppContext)

    return (
        <>
            <View style={{
                alignItems: 'center',
                flexDirection: 'row',
                padding: 10
            }}>
                <Text style={{
                    color: ctx.textColor,
                    fontFamily: 'Kiros',
                    fontSize: 25
                }}>
                    {ctx.user}
                </Text>
                <TouchableHighlight
                    style={{
                        marginLeft: 'auto'
                    }}
                    onPress={() => setShow(false)}>
                    <Ionicons name='close' color={ctx.textColor} size={30} />
                </TouchableHighlight>
            </View>
            <Hr />
        </>
    )
}